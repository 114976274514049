/** @module hooks/sortable */ import { urlParam, addUrlParam } from "../util";
const WP_TABLE_LIST_SELECTOR = ".wp-list-table.media tbody, .wp-list-table.attachments tbody";
/**
 * Apply an order to attachments browser without reloading the collection.
 */ export function applyToAttachmentsBrowser(attachmentsBrowser, selected, orderby, order) {
    if (orderby === void 0) orderby = "rml";
    if (order === void 0) order = "ASC";
    let filter;
    if (attachmentsBrowser && (filter = attachmentsBrowser.toolbar.get("rml_folder").filters[selected.id])) {
        const { props } = attachmentsBrowser.collection;
        const o = {
            silent: true
        };
        if (selected.contentCustomOrder === 1 || selected.forceCustomOrder) {
            filter.props.orderby = "rml";
            filter.props.order = "ASC";
            props.set({
                orderby,
                order
            }, o);
        } else {
            delete filter.props.orderby;
            delete filter.props.order;
            props.set({
                orderby: "date",
                order: "DESC"
            }, o);
        }
    }
}
if (process.env.PLUGIN_CTX === "pro") {
/* Silence is golden... Until you have PRO Version! */ }
/**
 * Checks if a filter is active.
 *
 * @param {object} [attachmentsBrowser] If set the filter is searched in the backbone controller
 */ export function isFilterActive(attachmentsBrowser) {
    if (attachmentsBrowser) {
        const filters = [
            "monthnum",
            "year",
            "uploadedTo",
            "type"
        ];
        const { props } = attachmentsBrowser.collection;
        for(let i = 0; i < filters.length; i++){
            if (props.get(filters[i])) {
                return true;
            }
        }
        return false;
    } else {
        // List
        return !!urlParam("attachment-filter");
    }
}
/**
 * Checks if a orderby is active.
 *
 * @param {object} [attachmentsBrowser] If set the filter is searched in the backbone controller
 */ export function isOrderByActive(attachmentsBrowser, orderby, order) {
    if (orderby === void 0) orderby = "rml";
    if (order === void 0) order = "ASC";
    if (attachmentsBrowser) {
        const { props } = attachmentsBrowser.collection;
        const propOrder = props.get("order") || "DESC";
        return props.get("orderby") === orderby && propOrder.toUpperCase() === order.toUpperCase();
    } else {
        // List
        const propOrder = urlParam("order") || "DESC";
        return urlParam("orderby") === orderby && propOrder.toUpperCase() === order.toUpperCase();
    }
}
/**
 * @returns {string}
 */ export function orderUrl(href) {
    if (href === void 0) href = window.location.href;
    return addUrlParam(addUrlParam(href, "orderby", "rml"), "order", "asc");
}
/**
 * (Pro only) Toggle the sortable mode. Popup a message if custom order is not disabled, yet.
 * If custom order is enabled check the different list and grid mode behavior.
 *
 * @param {object} selected The selected node
 * @parma {boolean} mode The mode to activate
 * @param {object} [attachmentsBrowser] If set the filter is searched in the backbone controller
 */ export function toggleSortable(selected, mode, attachmentsBrowser) {
    if (process.env.PLUGIN_CTX === "lite") {
        return false;
    } else {
    /* Silence is golden... Until you have PRO Version! */ }
}
