/**
 * Add a URL parameter (or changing it if it already exists)
 *
 * @param {string} url The url
 * @param {string} parameterName The parameter name
 * @param {string} parameterValue The parameter value
 * @param {boolean} [atStart] Add param before others
 * @returns {string} URL
 * @see http://stackoverflow.com/questions/486896/adding-a-parameter-to-the-url-with-javascript
 * @see http://stackoverflow.com/questions/6953944/how-to-add-parameters-to-a-url-that-already-contains-other-parameters-and-maybe?noredirect=1&lq=1
 * @module util/addUrlParam
 */ export default function addUrlParam(url, parameterName, parameterValue, atStart) {
    var replaceDuplicates = true;
    var urlhash;
    var sourceUrl;
    if (url.indexOf("#") > 0) {
        var cl = url.indexOf("#");
        urlhash = url.substring(url.indexOf("#"), url.length);
    } else {
        urlhash = "";
        cl = url.length;
    }
    sourceUrl = url.substring(0, cl);
    var urlParts = sourceUrl.split("?");
    var newQueryString = "";
    if (urlParts.length > 1) {
        var parameters = urlParts[1].split("&");
        for(var i = 0; i < parameters.length; i++){
            var parameterParts = parameters[i].split("=");
            if (!(replaceDuplicates && parameterParts[0] == parameterName)) {
                if (newQueryString == "") newQueryString = "?";
                else newQueryString += "&";
                newQueryString += `${parameterParts[0]}=${parameterParts[1] ? parameterParts[1] : ""}`;
            }
        }
    }
    if (newQueryString == "") newQueryString = "?";
    if (atStart) {
        newQueryString = `?${parameterName}=${parameterValue}${newQueryString.length > 1 ? `&${newQueryString.substring(1)}` : ""}`;
    } else {
        if (newQueryString !== "" && newQueryString != "?") newQueryString += "&";
        newQueryString += `${parameterName}=${parameterValue ? parameterValue : ""}`;
    }
    return urlParts[0] + newQueryString + urlhash;
}
