/** @module others/optionsScreen */ import $ from "jquery";
import { ajax, rmlOpts, hooks } from "../util";
/**
 * Do the options screen with a nav bar (WordPress standard). This
 * nav bar is not ReactJS.
 */ export default function() {
    // Append to known option
    const container = $('<div class="rml-options"><nav><ul></ul></nav></div>').insertBefore($('[for="rml_load_frontend"]').parents("table").prev());
    const nav = container.find("nav ul");
    let navLiCnt = 0;
    $("<h3>Real Media Library</h3>").insertBefore(container);
    // Search the option panels
    $("table.form-table").each(function() {
        const oHeadline = $(this).prev();
        let sHeadline = oHeadline.html();
        if (sHeadline && sHeadline.indexOf("RealMediaLibrary") === 0) {
            sHeadline = sHeadline.split(":", 2)[1];
            // Append headline to options panel
            const li = $(`<li class="nav-tab ${navLiCnt === 0 ? "nav-tab-active" : ""}">${sHeadline}</li>`).appendTo(nav);
            const section = $(this).appendTo(container);
            !navLiCnt && section.show();
            li.click(function() {
                container.children("table").hide();
                nav.find(".nav-tab-active").removeClass("nav-tab-active");
                $(this).addClass("nav-tab-active");
                section.show();
            });
            // Hash navigation
            let hashObj;
            let hash = window.location.hash.split("rml-", 2);
            if (hash.length > 1 && (hashObj = section.find(`#${hash[1]}`)).length) {
                li.click();
                // Scroll to element
                setTimeout(()=>$("html, body").animate({
                        scrollTop: hashObj.offset().top - 170
                    }, 500), 300);
            }
            oHeadline.remove();
            navLiCnt++;
        }
    });
    /**
     * Fired when the options screen tables are rendered successfully.
     *
     * @event module:util/hooks#options/ready
     */ hooks.call("options/ready");
}
/**
 * When a .rml-rest-button is pressed show a loading indicator and send
 * the request to the REST server.
 */ $(()=>$(document).on("click", ".rml-rest-button", function(e) {
        let { url, method, urlnamespace, ...data } = $(this).data();
        let btn = $(this);
        if ($(this).attr("disabled") !== "disabled" && window.confirm(rmlOpts.others.lang.areYouSure)) {
            btn.html('<div class="spinner is-active" style="float: initial;margin: 0;"></div>');
            btn.attr("disabled", "disabled");
            /**
             * Fired when a button with class .rml-rest-button gets clicked and
             * the POST data is prepared so you can modify it. The $url is the
             * data-url attribute of the button. You also have to define a
             * data-method attribute.
             *
             * @event module:util/hooks#rest/button/prepare/$url
             * @param {object} data The data
             * @this jQuery
             */ hooks.call(`rest/button/prepare/${url}`, [
                data
            ], btn);
            // Still use deprecated `ajax` method because the success arguments are passed to hooks
            ajax(url, {
                method,
                data
            }, urlnamespace).then(function() {
                for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                    args[_key] = arguments[_key];
                }
                btn.html(`<i class="fa fa-check"></i> ${rmlOpts.others.lang.success}`);
                /**
                     * Fired when a button with class .rml-rest-button is successfully saved.
                     *
                     * @event module:util/hooks#rest/button/success/$url
                     * @param {mixed} args... The $.ajax success arguments
                     * @param {string} method The method
                     * @param {object} data The data
                     * @this jQuery
                     */ hooks.call(`rest/button/success/${url}`, [
                    ...args,
                    method,
                    data
                ], btn);
            }, function() {
                for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                    args[_key] = arguments[_key];
                }
                btn.html(`<i class="fa fa-warning"></i> ${rmlOpts.others.lang.failed}`).attr("disabled", false);
                /**
                     * Fired when a button with class .rml-rest-button is successfully saved.
                     *
                     * @event module:util/hooks#rest/button/error/$url
                     * @param {mixed} args... The $.ajax success arguments
                     * @param {string} method The method
                     * @param {object} data The data
                     * @this jQuery
                     */ hooks.call(`rest/button/error/${url}`, [
                    ...args,
                    method,
                    data
                ], btn);
            });
        }
        e.preventDefault();
        return false;
    }));
hooks.register("rest/button/success/export", (response)=>{
    $("#rml_export_data textarea").get(0).value = response;
});
hooks.register("rest/button/prepare/import", (data)=>{
    data.import = encodeURIComponent($("#rml_import_data textarea").get(0).value);
});
