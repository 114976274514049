/** @module others/filter */ import store from "../store";
import $ from "jquery";
/**
 * The attachments filter selectors.
 */ export const FILTER_SELECTOR = ".attachment-filters.attachment-filters-rml";
/**
 * The filter select dropdown prepared as backbone object.
 */ export const filter = {
    id: "media-attachment-filters-rml",
    className: "attachment-filters attachment-filters-rml",
    lastSlugs: {},
    createFilters: function(namesSlug) {
        this.$el.data("backboneView", this);
        // default "all" filter, shows all tags
        const filters = this.filters = {
            all: {
                text: "All",
                props: {
                    rml_folder: "",
                    orderby: "date",
                    order: "DESC"
                },
                priority: 10
            }
        };
        // No filters loaded, yet
        if (namesSlug) {
            this.lastSlugs = namesSlug;
            const { names, slugs } = namesSlug;
            // create a filter for each tag
            let props;
            let node;
            for(let i = 0; i < names.length; i++){
                node = store.getTreeItemById(slugs[i]);
                props = {
                    rml_folder: slugs[i],
                    orderby: "date",
                    order: "DESC"
                };
                // Add order by
                if (node && (node.contentCustomOrder === 1 || node.forceCustomOrder)) {
                    props.orderby = "rml";
                    props.order = "ASC";
                }
                filters[slugs[i]] = {
                    text: names[i],
                    props,
                    priority: 20 + i
                };
            }
        }
        //this.model.set(filters['all'].props); // Implemented in mediaViews
        if (namesSlug) {
            // Build `<option>` elements.
            this.$el.html(Object.keys(this.filters).map((key)=>$("<option></option>").val(key).text(this.filters[key].text)[0].outerHTML).join(""));
            // Reselect
            this.select();
        }
    }
};
