/**
 * The RML WP RFC functionality allows you to create callbacks for specific
 * elements defined in the DOM. For example you have to fallback to HTML output
 * like the CustomField in attachment browser.
 *
 * You can otherwise use the attribute data-wprfc-visible="1" then the RFC is called when
 * the elemen is visible. You do not have to append an additional script.
 *
 * @example <caption>PHP side component</caption>
 * <div class="rml-wprfc" data-wprfc="breadcrumb"></div>
 * <script>jQuery(function() { window.rml.hooks.call("wprfc"); });</script>
 * @example <caption>JS side</caption>
 * window.rml.hooks.register('wprfc/breadcrumb', () => { });
 * @module util/wpRfc
 * @see module:util/hooks#wprfc/$function
 */ import $ from "jquery";
import hooks from "./hooks";
import "../others/rfcRpmHint";
import "../others/rfcShortcutInfo";
import "../others/rfcPreUploadUi";
const ready = (fn)=>(document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") ? fn() : document.addEventListener("DOMContentLoaded", fn);
const RFC_CLASS_NAME = "rml-wprfc";
/**
 * Interval visible rfc.
 */ ready(()=>{
    let fnSearch;
    (fnSearch = ()=>{
        $('[data-wprfc-visible="1"]').filter(':visible,input[type="hidden"]').removeClass(`${RFC_CLASS_NAME}-visible`).each(function() {
            $(this).attr("data-wprfc-visible", "2");
            /**
                 * A RML WP RFC is called and should be handled.
                 *
                 * @event module:util/hooks#wprfc/$function
                 * @param {object} data The element data
                 * @param {jQuery} $el The element
                 */ hooks.call(`wprfc/${$(this).attr("data-wprfc")}`, $(this).data(), $(this));
        });
        setTimeout(fnSearch, 500);
    })();
});
/**
 * Usual scripted rfc.
 */ hooks.register("wprfc", ()=>ready(()=>$(`.${RFC_CLASS_NAME}`).removeClass(RFC_CLASS_NAME).each(function() {
            $(this).is(":visible") && hooks.call(`wprfc/${$(this).attr("data-wprfc")}`, $(this).data(), $(this));
        })));
