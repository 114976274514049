/** @module others/rfcPreUploadUi */ import $ from "jquery";
import hooks from "../util/hooks";
import rmlOpts from "rmlopts";
import createFolderSelector from "../components/FolderSelector";
import store from "../store";
/**
 * Load data to a dropdown or show label that the folder is inherited from the AppTree.
 * This RFC is placed in the upload UI where you can select your files.
 *
 * @function preUploadUi
 * @listens module:util/hooks#wprfc/$function
 */ hooks.register("wprfc/preUploadUi", async function(data) {
    const compatView = !!$(this).parent(".rml-compat-preUploadUi").length;
    let attachmentsBrowser = $(this).parents(".attachments-browser");
    // Fallback to main media library page
    compatView && !attachmentsBrowser.length && (attachmentsBrowser = $(".attachments-browser"));
    if (attachmentsBrowser.length && !compatView) {
        $(this).parent().hide().prev().html(rmlOpts.others.lang.uploaderUsesLeftTree);
    } else {
        const span = $("<span/>").addClass(compatView ? undefined : "attachments-filter-preUploadUi").insertAfter($(this)).get(0);
        createFolderSelector(span, $(this).get(0), Object.assign(data, {
            style: {
                maxWidth: 400
            },
            // When in compat view raise the change event manually so it is saved
            onSelect: compatView && function(node, input) {
                var _attachmentsBrowser_data;
                (_attachmentsBrowser_data = attachmentsBrowser.data("backboneView")) == null ? void 0 : _attachmentsBrowser_data.controller.once("attachment:compat:ready", ()=>store.fetchCounts());
                $(input).trigger("change");
            }
        }));
    }
});
