/** @module hooks/modal */ import $ from "jquery";
/**
 * Get the backbone controller of the modal.
 *
 * @param {object} element The DOM element
 * @returns {Controller} The backbone controller
 */ export function getModalControllerOf(element) {
    try {
        return $(element).parents(".rml-modal").data("backboneView").controller;
    } catch (e) {
        return null;
    }
}
/**
 * The sortable state gets refreshed so check if we have to destroy the
 * draggable instance.
 *
 * @returns {boolean}
 */ export function isAttachmentsBrowserSortable(element) {
    try {
        const { attachments } = element.attachmentsBrowser;
        return !attachments.$el.sortable("option", "disabled");
    } catch (e) {
        return false;
    }
}
/**
 * @returns {boolean}
 */ export function isAttachmentsGalleryEdit(attachments) {
    try {
        return [
            "gallery-edit",
            "playlist-edit",
            "video-playlist-edit"
        ].indexOf(attachments.options.model.get("toolbar")) > -1;
    } catch (e) {
        return false;
    }
}
if (process.env.PLUGIN_CTX === "pro") {
/* Silence is golden... Until you have PRO Version! */ }
