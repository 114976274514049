/**
 * Create a WP RFC for the Real Physical Media hint in the "Copy URL" attchment field.
 *
 * @module others/rfcShortcutInfo
 */ import $ from "jquery";
import hooks from "../util/hooks";
import { rmlOpts } from "../util";
/**
 * Create a WP RFC for the Real Physical Media hint in the "Copy URL" attchment field.
 *
 * @property {id} id The attachment id.
 * @function shortcutInfo
 * @listens module:util/hooks#wprfc/$function
 */ hooks.register("wprfc/shortcutInfo", async function() {
    if (window.realPhysicalMedia) {
        return;
    }
    let description = $('<p class="description" />');
    const attachmentPageContainer = $("body.wp-admin.post-type-attachment .misc-pub-section.misc-pub-attachment");
    const attachmentDialogContainer = $(this).parents(".attachment-details").find('.setting[data-setting="url"]');
    // Avoid RC duplicate hints
    if (attachmentDialogContainer.data("rpmHintAdded")) {
        return;
    }
    attachmentDialogContainer.data("rpmHintAdded", true);
    if (attachmentDialogContainer.length) {
        // Media dialog
        attachmentDialogContainer.addClass("has-description");
        description = description.insertAfter(attachmentDialogContainer);
    } else if (attachmentPageContainer.length) {
        // Media page
        description = description.appendTo(attachmentPageContainer);
    } else {
        return;
    }
    // Alter text for this description
    description.html(rmlOpts.others.lang.settingCopyLinkInfoRpm);
});
