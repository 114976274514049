import { urlParam } from "../util";
import rmlOpts from "rmlopts";
const ID_NONE = -2;
const ID_LAST_QUERIED = -3;
function adjustLinks(df) {
    if (df === void 0) df = getDefaultFolder(false);
    if (process.env.PLUGIN_CTX === "pro") {
    /* Silence is golden... Until you have PRO Version! */ }
}
/**
 * Get the default folder and resolve if for example "Last queried folder".
 *
 * @param {boolean} [resolve=true] If false the rmlOpts.others.defaultFolder is returned in raw, so -2 is possible for example
 * @returns {number}
 */ function getDefaultFolder(resolve) {
    if (resolve === void 0) resolve = true;
    let id;
    if (process.env.PLUGIN_CTX === "pro") {
    /* Silence is golden... Until you have PRO Version! */ }
    // Higher priority list mode query parameter
    id = urlParam("rml_folder") || id;
    // Resolve
    switch(id){
        case ID_LAST_QUERIED:
            return +rmlOpts.others.lastQueried;
        default:
            return id;
    }
}
if (process.env.PLUGIN_CTX === "pro") {
/* Silence is golden... Until you have PRO Version! */ }
export { ID_NONE, ID_LAST_QUERIED, getDefaultFolder };
